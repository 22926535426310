<template>
  <VueCtkDateTimePicker
    v-model="innerModel"
    :custom-shortcuts="shortcuts"
    format="YYYY-MM-DD"
    color="#42A5F5"
    @input="input($event)"
    range
    inline
    noKeyboard
    :dark="$vuetify.theme.dark"
    :max-date="maxDateSetup"
    :min-date="minDateSetup"
    :first-day-of-week="1"
  />
</template>
<script>
import moment from 'moment-timezone';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

export default {
  name: 'date-range',
  components: {
    VueCtkDateTimePicker,
  },
  prevStartDate: moment().startOf('day').tz('GMT', true).toISOString(),
  prevEndDate: moment().endOf('day').tz('GMT', true).toISOString(),
  data: () => ({
    innerModel: {
      start: moment().startOf('day').tz('GMT', true).toISOString(),
      end: moment().endOf('day').tz('GMT', true).toISOString(),
    },
    standardShortcuts: [
      { key: 'yesterday', label: 'Yesterday', value: '-day' },
      { key: 'today', label: 'Today', value: 'day' },
      {
        key: 'thisWeek',
        label: 'This week',
        value: () => {
          const thisWeekStart = moment().startOf('isoWeek');
          const thisWeekEnd = moment().endOf('isoWeek');
          return {
            start: thisWeekStart,
            end: thisWeekEnd,
          };
        },
      },
      {
        key: 'lastWeek',
        label: 'Last week',
        value: () => {
          const lastWeekStart = moment().startOf('isoWeek').subtract(1, 'week');
          const lastWeekEnd = moment().endOf('isoWeek').subtract(1, 'week');
          return {
            start: lastWeekStart,
            end: lastWeekEnd,
          };
        },
      },
      {
        key: 'last7Days',
        label: 'Last 7 days',
        value: () => {
          return {
            start: moment().subtract(7, 'days'),
            end: moment().subtract(1, 'days'),
          };
        },
      },
      {
        key: 'last30Days',
        label: 'Last 30 days',
        value: () => {
          return {
            start: moment().subtract(30, 'days'),
            end: moment().subtract(1, 'days'),
          };
        },
      },
      { key: 'thisMonth', label: 'This month', value: 'month' },
      { key: 'lastMonth', label: 'Last month', value: '-month' },
    ],
  }),
  props: {
    groupBy: {
      type: String,
      required: false,
    },
    value: {
      type: Object,
      required: false,
      default: () => ({
        fromDate: moment().startOf('day').tz('GMT', true).toISOString(),
        toDate: moment().endOf('day').tz('GMT', true).toISOString(),
      }),
    },
  },
  computed: {
    maxDateSetup() {
      return moment().endOf('day').format('YYYY-MM-DD');
    },
    minDateSetup() {
      let groupByUnit = 'day';
      let minDate;
      switch (this.groupBy) {
        case 'year':
          groupByUnit = 'year';
          minDate = moment().startOf('day').subtract(24, 'months');
          break;
        case 'month':
          groupByUnit = 'month';
          minDate = moment().startOf('day').subtract(24, 'months');
          break;
        case 'week':
          groupByUnit = 'isoWeek';
          minDate = moment().startOf('day').subtract(60, 'days');
          break;
        case 'hour':
          minDate = moment().startOf('day').subtract(1, 'days');
          break;
        case 'minute':
          minDate = moment().startOf('day');
          break;
        default:
          minDate = moment().startOf('day').subtract(60, 'days');
      }
      let startDate, endDate;
      if (!this.prevEndDate) {
        endDate = moment(this.prevStartDate).endOf(groupByUnit);
      } else {
        endDate = moment(this.prevEndDate).endOf(groupByUnit);
      }
      if (endDate > moment()) {
        endDate = moment();
      }
      startDate = moment(endDate).startOf(groupByUnit);
      if (startDate < minDate) {
        startDate = minDate;
        endDate = moment(startDate).endOf(groupByUnit);
      }
      this.innerModel = {
        start: startDate.tz('GMT', true).toISOString(),
        end: endDate.tz('GMT', true).toISOString(),
      };
      return minDate.format('YYYY-MM-DD');
    },
    shortcuts() {
      const yearShortcuts = [];
      const monthShortcuts = [
        { key: 'thisMonth', label: 'This month', value: 'month' },
        { key: 'lastMonth', label: 'Last month', value: '-month' },
      ];
      const weekShortcuts = [
        {
          key: 'thisWeek',
          label: 'This week',
          value: () => {
            const thisWeekStart = moment().startOf('isoWeek');
            const thisWeekEnd = moment().endOf('isoWeek');
            return {
              start: thisWeekStart,
              end: thisWeekEnd,
            };
          },
        },
        {
          key: 'lastWeek',
          label: 'Last week',
          value: () => {
            const lastWeekStart = moment().startOf('isoWeek').subtract(1, 'week');
            const lastWeekEnd = moment().endOf('isoWeek').subtract(1, 'week');
            return {
              start: lastWeekStart,
              end: lastWeekEnd,
            };
          },
        },
      ];
      const hourShortcuts = [
        { key: 'yesterday', label: 'Yesterday', value: '-day' },
        { key: 'today', label: 'Today', value: 'day' },
      ];
      const minuteShortcuts = [{ key: 'today', label: 'Today', value: 'day' }];
      switch (this.groupBy) {
        case 'year':
          return yearShortcuts;
        case 'month':
          return monthShortcuts;
        case 'week':
          return weekShortcuts;
        case 'hour':
          return hourShortcuts;
        case 'minute':
          return minuteShortcuts;
        default:
          return this.standardShortcuts;
      }
    },
  },
  methods: {
    setMaxDateOnNull() {
      if (!this.innerModel.end) {
        this.innerModel.end = moment().endOf('day').tz('GMT', true).toISOString();
      }
    },
    input(value) {
      let groupByUnit;
      switch (this.groupBy) {
        case 'week':
          groupByUnit = 'isoWeek';
          break;
        case 'month':
          groupByUnit = 'month';
          break;
        case 'year':
          groupByUnit = 'year';
          break;
        default:
          groupByUnit = 'day';
      }
      if (value) {
        value.start = moment(value.start).startOf(groupByUnit);
        if (value.end) {
          value.end = moment(value.end).endOf(groupByUnit);
          if (value.end > moment()) {
            value.end = moment();
          }
          this.innerModel.start = value.start.tz('GMT', true).toISOString();
          this.innerModel.end = value.end.tz('GMT', true).toISOString();
        }
      }
    },
  },
  watch: {
    innerModel(newValue, oldValue) {
      this.prevStartDate = oldValue.start;
      this.prevEndDate = oldValue.end;
      let result = {
        fromDate: newValue.start,
        toDate: newValue.end,
      };
      this.$emit('input', result);
    },
  },
  mounted() {
    this.innerModel = {
      start: moment().startOf('day').tz('GMT', true).toISOString(),
      end: moment().endOf('day').tz('GMT', true).toISOString(),
    };
  },
};
</script>
<style>
.shortcuts-container {
  height: 100% !important;
}
</style>
