import ENTITIES from './Entities';
import OfferStatus from './OfferStatus';

export const CHIP_TYPE = {
  SELECTION: 'SELECTION',
  LIST_ITEM: 'LIST_ITEM',
};

export const CUSTOM_CHIP_COLORS = {
  [ENTITIES.OFFER]: {
    [CHIP_TYPE.SELECTION]: {
      chip: {
        [OfferStatus.Active]: {
          dark: '#116600',
          light: '#4CAF50',
        },
        [OfferStatus.Paused]: {
          dark: '#FADD90',
          light: '#FADD90',
        },
      },
      text: {
        [OfferStatus.Active]: {
          dark: '#FFFFFF',
          light: '#000000',
        },
        [OfferStatus.Paused]: {
          dark: '#F17F23',
          light: '#F17F23',
        },
      },
    },
  },
  [ENTITIES.USER]: {
    [CHIP_TYPE.LIST_ITEM]: {
      chip: 'primary',
      text: '',
    },
  },
  [ENTITIES.STATUS]: {
    chip: {
      ACTIVE: 'green',
      RUNNING: 'green',
      DONE: 'green',
      IN_PROGRESS: 'primary',
      PENDING: 'orange',
      PAUSED: 'orange',
      INACTIVE: 'orange',
      EXPIRED: 'red',
      DELETED: 'red',
      BLOCKED: 'red',
      REJECTED: 'red',
      CANCELLED: 'red',
      TEST: 'primary',
    },
    text: '#FFFFFF',
  },
};
